import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import './certificate.scss';
import data from "../../api";
import openSocket from "socket.io-client";
import { ClipLoader } from "react-spinners";
import BeatLoader from "react-spinners/BeatLoader";
import logo from '../../EarthID Logo - Primary - BLACK.png'
import favicon from '../../EarthID FAVICON - PRIMARY.png'
import check from '../../check.png'



function formatDate(date) {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    
    // Ensure leading zeroes for day and month
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;
    
    return `${formattedDay}/${formattedMonth}/${year}`;
  }


const EmpCertificate = () => {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const bytes = JSON.parse(decodeURIComponent(query.get('bytes')));


  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isVerificationLoading, setIsVerificationLoading] = useState([true, true, true, true, true, true, true]);
  const [verificationData, setVerificationData] = useState([]);
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  const currentDate = new Date();
  const formattedDate = formatDate(currentDate);


  useEffect(() => {
    // Simulating data fetching delay
    const delay = 1000;

    if (isButtonClicked) {
      setIsLoading(true);
      setIsVerificationLoading([true, true, true, true, true, true, true]);
      setVerificationData([]);

      setTimeout(() => {
        setIsLoading(false);
        setIsVerificationLoading([true, true, true, true, true, true, true]);
        setVerificationData([formattedDate, "EarthID", bytes.earthId, "Shiv Aggarwal", "CTO EarthID"]);
        setTimeout(() => {
          setIsVerificationLoading([false, true, true, true, true, true, true]);
          setVerificationData([formattedDate, "EarthID", bytes.earthId, "Shiv Aggarwal", "CTO EarthID"]);
          setTimeout(() => {
            setIsVerificationLoading([false, false, true, true, true, true, true]);
            setVerificationData([formattedDate, "EarthID", bytes.earthId, "Shiv Aggarwal", "CTO EarthID"]);
            setTimeout(() => {
              setIsVerificationLoading([false, false, false, true, true, true, true]);
              setVerificationData([formattedDate, "EarthID", bytes.earthId, "Shiv Aggarwal", "CTO EarthID"]);
              setTimeout(() => {
                setIsVerificationLoading([false, false, false, false, true, true, true]);
                setVerificationData([formattedDate, "EarthID", bytes.earthId, "Shiv Aggarwal", "CTO EarthID"]);
                setTimeout(() => {
                  setIsVerificationLoading([false, false, false, false, false, true, true]);
                  setVerificationData([formattedDate, "EarthID", bytes.earthId, "Shiv Aggarwal", "CTO EarthID"]);
                  setTimeout(() => {
                    setIsVerificationLoading([false, false, false, false, false, false, true]);
                    setVerificationData([formattedDate, "EarthID", bytes.earthId, "Shiv Aggarwal", "CTO EarthID"]);
                    setTimeout(() => {
                      setIsVerificationLoading([false, false, false, false, false, false, false]);
                      setVerificationData([formattedDate, "EarthID", bytes.earthId, "Shiv Aggarwal", "CTO EarthID"]);
            }, delay);
          }, delay);
        }, delay);
      }, delay);
    }, delay);
  }, delay);
}, delay);
}, delay);
    }

    setIsButtonClicked(false);
  }, [isButtonClicked]);

  const handlePopupOpen = () => {
    setIsPopupOpen(true);
    setIsButtonClicked(true);
  };

  const handlePopupClose = () => {
    setIsPopupOpen(false);
  };


  return (
    <div className="page-container">
      <nav className="navbar">
        <div className="logo">
          <img src={logo}></img>
        </div>
        <ul className="nav-menu">
          <li className="nav-item"><a href="/">Home</a></li>
        </ul>
      </nav>
      <div className="certificate-container">
        <section className='certBack'>
          <div className="certificate">
            <div className="bodbox">

              <div className="header">
                <div className="favico">
                  <img src={favicon}></img>
                </div>
                <div className="title">Employment Certificate</div>
              </div>

              <div className="title1">This document verifies that</div>

              <div className="body">
                <div className="name">Shiv Aggarwal</div>
                <div className="title1">is a valid employee of EarthID. <br />
  Having successfully completed all requirements for the certified professional course, <br />
  with advanced understanding and experience <br />
  with exceptional dedication and performance.
                </div>
              </div>
              <div>
                <div className='signature'>Priya Guliani</div>
                <div className='signature1'>CEO EarthID</div>
              </div>


            </div>
          </div>
        </section>
      </div>
      <section className='infosec'>
        <div className='container'>
          <div className='row'>
            <div className='leftsec col-md-8'>
              <div className='row'>
                <div className="check col-md-1">
                  <img src={check}></img>
                </div>
                <h2 className='coursename col-md-11'>Employment Certificate</h2>
              </div>

              <div className='stuname'>Shiv Aggarwal</div>
              <div className='stuID'>ID: {bytes.earthId}</div>
              <hr></hr>
              <div className='description'>
              This is to certificate represents that Shiv Aggarwal is currently employed at EarthID. They have been an integral part of our team. Their contributions have significantly impacted our projects, and they consistently deliver high-quality work within specified deadlines. 
              And has demonstrated an ability to adapt to new technologies and methodologies, staying up-to-date with industry trends and advancements.
              </div>
              <hr></hr>
              <div className='row'>
                <div className='issueon col-md-6'>
                  <div>Issued On</div>
                  <div>{formattedDate}</div>
                </div>
                <div className='expiry col-md-6'>
                  <div>Expires On</div>
                  <div>NA</div>
                </div>
              </div>
            </div>
            <div className='col-md-4'>
              <div className="footer1">
                <div className='verify'>Credential Verification</div>
                <div className='verify1'>This credential is from a verified issuer</div>
                <button className="verify-btn" onClick={handlePopupOpen}>
                  Verify
                </button>
              </div>
              <div className="footer1">
                <div className='verify'>More about the Issuer</div>
                <div className='verify1'>EarthID</div>
                <button className="verify-btn" onClick={() => window.open('https://hospital.myearth.id/', '_blank', 'noopener')} rel="noopener">
                  Visit Issuer Website
                </button>
              </div>
            </div>

          </div>

        </div>

      </section>

      {isPopupOpen && (
        <div className="popup-container">
          {isLoading ? (
            <div>
              <div className="spinner"></div>
              <div className="spinner-text">Verifying Credentials...</div>
            </div>
          ) : (
            <div className="popup">
              <div className="popup-header">
                <div className="popup-title">Verification Details</div>
                <button className="popup-close" onClick={handlePopupClose}>
                  X
                </button>
              </div>
              <hr></hr>
              <div className="popup-body">
                {isVerificationLoading[0] ? (
                  <div>
                    <div className="verification-item">
                      <div className="verification-result">
                        <div className="spinner1"></div>
                      </div>
                      <div className="verification-label">Issuer is Valid:</div>
                    </div>
                    <hr></hr>
                  </div>

                ) : (
                  <div>
                    <div className="verification-item">
                      <div className="verification-result">
                        <div className="success">&#10004;</div>
                      </div>
                      <div className="verification-label">Issuer is Valid:</div>

                      <div className="verification-result1">
                        <div>The issuer is found to be valid</div>
                      </div>
                    </div>
                    <hr></hr>
                  </div>

                )}
                {isVerificationLoading[1] ? (
                  <div>
                    <div className="verification-item">
                      <div className="verification-result">
                        <div className="spinner1"></div>
                      </div>
                      <div className="verification-label">Issued on:</div>
                    </div>
                    <hr></hr>
                  </div>

                ) : (
                  <div>
                    <div className="verification-item">
                      <div className="verification-result">
                        <div className="success">&#10004;</div>
                      </div>
                      <div className="verification-label">Issued on:</div>

                      <div className="verification-result1">
                        {verificationData[0] && <div>{verificationData[0]}</div>}
                      </div>
                    </div>
                    <hr></hr>
                  </div>

                )}
                {isVerificationLoading[2] ? (
                  <div>
                    <div className="verification-item">
                      <div className="verification-result">
                        <div className="spinner1"></div>
                      </div>
                      <div className="verification-label">Issued by:</div>
                    </div>
                    <hr></hr>
                  </div>

                ) : (
                  <div>
                    <div className="verification-item">
                      <div className="verification-result">
                        <div className="success">&#10004;</div>
                      </div>
                      <div className="verification-label">Issued by:</div>

                      <div className="verification-result1">
                        {verificationData[1] && <div>{verificationData[1]}</div>}
                      </div>
                    </div>
                    <hr></hr>
                  </div>

                )}
                {isVerificationLoading[3] ? (
                  <div>
                    <div className="verification-item">
                      <div className="verification-result">
                        <div className="spinner1"></div>
                      </div>
                      <div className="verification-label">EarthID:</div>
                    </div>
                    <hr></hr>
                  </div>

                ) : (
                  <div>
                    <div className="verification-item">
                      <div className="verification-result">
                        <div className="success">&#10004;</div>
                      </div>
                      <div className="verification-label">EarthID:</div>

                      <div className="verification-result1">
                        {verificationData[2] && <div>{verificationData[2]}</div>}
                      </div>
                    </div>
                    <hr></hr>
                  </div>

                )}
                {isVerificationLoading[4] ? (
                  <div>
                    <div className="verification-item">
                      <div className="verification-result">
                        <div className="spinner1"></div>
                      </div>
                      <div className="verification-label">Issued to:</div>
                    </div>
                    <hr></hr>
                  </div>

                ) : (
                  <div>
                    <div className="verification-item">
                      <div className="verification-result">
                        <div className="success">&#10004;</div>
                      </div>
                      <div className="verification-label">Issued to:</div>

                      <div className="verification-result1">
                        {verificationData[3] && <div>{verificationData[3]}</div>}
                      </div>
                    </div>
                    <hr></hr>
                  </div>

                )}
                {isVerificationLoading[5] ? (
                  <div>
                    <div className="verification-item">
                      <div className="verification-result">
                        <div className="spinner1"></div>
                      </div>
                      <div className="verification-label">Designation:</div>

                    </div>
                    <hr></hr>
                  </div>

                ) : (
                  <div>
                    <div className="verification-item">
                      <div className="verification-result">
                        <div className="success">&#10004;</div>
                      </div>
                      <div className="verification-label">Designation:</div>

                      <div className="verification-result1">
                        {verificationData[4] && <div>{verificationData[4]}</div>}
                      </div>
                    </div>
                    <hr></hr>
                  </div>

                )}
                {isVerificationLoading[6] ? (
                  <div>
                    <div className="verification-item">
                      <div className="verification-result">
                        <div className="spinner1"></div>
                      </div>
                      <div className="verification-label"></div>
                    </div>
                    <hr></hr>
                  </div>

                ) : (
                  <div>
                    <div className="verification-item">
                      <div className="verification-result">
                        <div className="success1"><img src={check}></img></div>
                      </div>
                      <div className="verification-label"></div>

                      <div className="verification-result1">
                        <div className='verified'>VERIFIED</div>
                      </div>
                    </div>
                    <hr></hr>
                  </div>

                )}
              </div>
            </div>
          )}
        </div>
      )}
      <footer className="footer">
        <div className="logo"></div>
        <div className="footer-text">
          © 2023 EarthID, Inc. All Rights Reserved.
        </div>
      </footer>
    </div>
  );
};

export default EmpCertificate;
