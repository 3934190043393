import React, { Component } from "react";
import openSocket from "socket.io-client";
import CryptoJS from "react-native-crypto-js";
import crypto, { createHmac } from "crypto";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import GenerateCred from "./components/GenerateCred/generateCred";
import VerifyCreditnals from "./components/VerifyCreditnals/generateCred";
import data from "./api";
import { store } from "react-notifications-component";
import "./App.css";
import Swal from "sweetalert2";
import { SHA256 } from "crypto-js";



import {faTwitter, faFacebook, faYoutube, faLinkedinIn, faInstagram} from "@fortawesome/free-brands-svg-icons";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const isTestnet = true;
const socketURL =
  isTestnet === true
    ? "https://socket-stagev2.myearth.id"
    : "https://socketv2.myearth.id";
const socket = openSocket(socketURL, {
  transports: ["websocket", "polling", "flashsocket"],
});
let socketId;
socket.on("connect", () => {
  socketId = socket.id;
  console.log(`I'm connected with the back-end`);
});

const {
  listenForUserData,
  listenForServiceProviderResponse,
  getApiKey,
  issuerDidgenerateqr,
  createIssuerDid,
  generateQrCodeNest,
  getUserCreditnals,
  generateqr,
  verifier_listenForUserData,
  verifier_listenForServiceProviderResponse,
  verifier_generateqr,
  verifyCreditnals,
  registerVendor,
} = data;

let showNotification = true;

class Home extends Component {
  state = {
    showSpinner: false,
    showBtn: true,
    requestNo: "",
    qrcode: false,
    response: false,
    email: "",
    mno: "",
    profileDrop: false,
    btnText: "Login with Earth Id",
    showText: true,
    showModal: true,
    modalHeading: false,
    createDid: "",
    creditnals: "",
  };

  loader = () => {
    this.setState({ showBtn: false, modalHeading: true });
    this.setState({ qrcode: true, showSpinner: false });
    if (!this.state.showModal) {
      this.setState({
        showModal: true,
      });
    }
    if (this.state.loggedIn) {
      localStorage.removeItem("email");
      localStorage.removeItem("mobile_no");
      localStorage.removeItem("user_name");
      localStorage.removeItem("dob");
      localStorage.removeItem("e_verified");
      localStorage.removeItem("trust_score");
      localStorage.removeItem("earth_id");
      localStorage.removeItem("m_verified");
      this.setState({
        loggedIn: false,
        btnText: "Login with Earth Id",
        qrcode: true,
        response: false,
        showModal: false,
      });
      setTimeout(() => {
        document.getElementById("exampleModalLogin").click();
      }, 0);
      this.redirectToHome();
    }
  };

  componentDidMount = async () => {
    if (localStorage.email != null) {
        this.setState({
            loggedIn: true,
            btnText: "LogOut",
        });
    }
    if (!this.state.apiKey) {
        this.state.apiKey = getApiKey();
    }
};

updateNotificationState = () => {
    setTimeout(() => {
        showNotification = true;
    }, 100);
    this.redirectToGenerateCred();
};

verifyNotificationState = () => {
    setTimeout(() => {
        showNotification = true;
    }, 100);
    this.redirectToVerifyCreditnals();
};

userDenied = () => {
    this.setState({
        response: false,
        showModal: false,
    });
    setTimeout(() => {
        document.getElementById("exampleModalLogin").click();
        if (showNotification === true) {
            showNotification = false;
            store.addNotification({
                title: "Error!",
                message: `User Didn't Authorize!`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true,
                },
            });
        }
    }, 0);
};

listenForSocket = () => {
    listenForServiceProviderResponse((err, res) => {
      // console.log("nikhil", res);
        this.setState({
            qrcode: false,
            response: false,
            showSpinner: true,
            showText: false,
        });
    });

  listenForUserData(async (err, res) => {
        let bytes = res.userDetails || res.newreq;
        console.log("bytes", bytes);
        console.log("testing-----",res.userDetails);
        console.log("testing-------",res.newreq );
        console.log("nikhil", res);
        this.setState({
            showQr: false,
            showSpinner: false,
        });
        let creditnals;
        let sessionKey;
        if (bytes === "denied" || bytes.permission === "denied") {
            this.userDenied();
        } else if (
            bytes.permission === "granted" ||
            bytes === "issuerLogin" ||
            bytes === "credentialsgenerated" ||
            bytes === "verifylogin" ||
            bytes === "success"
        ) {
            this.setState({
                response: true,
                showSpinner: false,
                loggedIn: true,
                showModal: false,
                btnText: "LogOut",
                bytes: "issuerLogin",
            });
            setTimeout(() => {
                document.getElementById("exampleModalLogin").click();
                let popMessage;
                if (showNotification === true) {
                    if (this.state.bytes === "credentialsgenerated") {
                        popMessage = "Credentials Generated";
                    } else {
                        popMessage = `Login Successful`;
                    }
                    showNotification = false;
                    store.addNotification({
                        title: "Success!",
                        message: popMessage,
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 3000,
                            onScreen: true,
                        },
                    });
                    if (bytes.permission === "granted") {
                        this.updateNotificationState();
                    } else if (bytes === "verifylogin") {
                        this.verifyNotificationState();
                    }
                }
            }, 0);
        } else if (bytes.userDetails.credentialType != null) {
            console.log("bytes", bytes);
            sessionKey = bytes.sessionKey;
            await getUserCreditnals(bytes.userDetails)
                .then((data) => {
                    this.userCreditnalsSuccess(bytes);

                    creditnals = data;
                })
                .catch((err) => {
                    alert(err);
                });
        } else {
            this.setState({
                response: false,
                showModal: false,
            });
            setTimeout(() => {
                document.getElementById("exampleModalLogin").click();
                if (showNotification === true) {
                    showNotification = false;
                    store.addNotification({
                        title: "Error!",
                        message: `User Didn't Authorize!`,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 3000,
                            onScreen: true,
                        },
                    });
                }
            }, 0);
        }
    });
};


listenForSocket_cred = () => {
  listenForServiceProviderResponse((err, res) => {
    console.log("nikhil", res);
      this.setState({
          qrcode: false,
          response: false,
          showSpinner: true,
          showText: false,
      });
  });

listenForUserData(async (err, res) => {
      let bytes = res.userDetails || res.newreq;
      console.log("bytes", bytes);
      
      if (bytes === "denied" || bytes.permission === "denied") {
          this.userDenied();
      } else if(
          bytes.permission === "granted" ||
          bytes === "issuerLogin" ||
          bytes === "credentialsgenerated" ||
          bytes === "verifylogin" ||
          bytes === "success"
      ){
          this.setState({
              response: true,
              showSpinner: false,
              loggedIn: true,
              showModal: false,
              btnText: "LogOut",
              bytes: "credentialsgenerated"
          });
          Swal.fire({
            title: 'Loading...',
            showDenyButton: false,
            showCancelButton: false,
            confirmButtonText: `View Certificate`,
            denyButtonText: `Copy URL`
          });
          
          Swal.showLoading();
          
          setTimeout(() => {
            Swal.hideLoading();
          
            Swal.fire({
              title: 'What do you want to do?',
              showDenyButton: true,
              showCancelButton: false,
              confirmButtonText: `View Certificate`,
              denyButtonText: `Copy URL`,
              customClass: {
                container: 'custom-popup-container',
                title: 'custom-popup-title',
                content: 'custom-popup-content',
                confirmButton: 'custom-popup-confirm-button',
                denyButton: 'custom-popup-deny-button',
            }
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                // Download PDF file
                window.open(`/certificate?bytes=${encodeURIComponent(JSON.stringify(bytes))}`, '_blank');
              } else if (result.isDenied) {
                // Copy URL to clipboard
                navigator.clipboard.writeText('http://15.207.193.210:3005/certificate/E123456');
              }
            });
          }, 3000);

      }; 
  });
};

userCreditnalsSuccess = (data) => {
    if (data.userDetails.credentialType != null) {
        this.setState({
            response: true,
            showSpinner: false,
            loggedIn: true,
            showModal: false,
            btnText: "LogOut",
        });
        setTimeout(() => {
            document.getElementById("exampleModalCred").click();
            let popMessage;
            if (showNotification === true) {
                popMessage = "Credentials Generated";
                showNotification = false;
                store.addNotification({
                    title: "Success!",
                    message: popMessage,
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 3000,
                        onScreen: true,
                    },
                });
            } else {
                this.setState({
                    response: false,
                    showModal: false,
                });
                setTimeout(() => {
                    document.getElementById("exampleModalCred").click();
                    if (showNotification === true) {
                        showNotification = false;
                        store.addNotification({
                            title: "Error!",
                            message: `User Didn't Authorize!`,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 3000,
                                onScreen: true,
                            },
                        });
                    }
                }, 0);
            }
        }, 0);
    }
};

generateQrCodeData = async () => {
    // issuerDidgenerateqr("login", "loginDetailsEncryption")
    // .then(async (data) => {
    // this.listenForSocket();
    // this.setState({
    // showQr: true,
    // qrCodeData: data,
    // apiKey: this.state.apikey,
    // qrcode: false,
    // });
    // })
    // .catch((err) => {
    // alert(err);
    // });
    registerVendor()
        .then((d) => {
            const timestamp = Date.now();
            const hash = crypto
                .createHmac("sha256", d.secretKey)
                .update(JSON.stringify(d.apiKey + "Rejolut@1008" + timestamp))
                .digest("hex");
            generateQrCodeNest(hash, d.apiKey, "Rejolut@1008", timestamp, "login")
                .then((data) => {
                    this.listenForSocket();
                    this.setState({
                        showQr: true,
                        qrCodeData: data,
                        apiKey: this.state.apikey,
                        qrcode: false,
                    });
                })
                .catch((err) => {
                    alert(err);
                });
        })
        .catch((err) => {
            alert(err);
        });
};

generateCredQrCodeData = () => {
    registerVendor()
        .then((d) => {
            const timestamp = Date.now();
            const hash = crypto
                .createHmac("sha256", d.secretKey)
                .update(JSON.stringify(d.apiKey + "Rejolut@1008" + timestamp))
                .digest("hex");
            generateQrCodeNest(
                hash,
                d.apiKey,
                "Rejolut@1008",
                timestamp,
                "generateCredentials"
            )
                .then((data) => {
                    this.listenForSocket_cred();
                    this.setState({
                        showQr: true,
                        qrCodeData: data,
                        apiKey: this.state.apikey,
                        qrcode: false,
                    });
                    console.log("test");
                })
                .catch((err) => {
                    alert(err);
                });
        })
        .catch((err) => {
            alert(err);
        });
};


/********* KYC function ************/  
QrCodeDataKYC = () => {
  registerVendor()
      .then((d) => {
          const timestamp = Date.now();
          const hash = crypto
              .createHmac("sha256", d.secretKey)
              .update(JSON.stringify(d.apiKey + "Rejolut@1008" + timestamp))
              .digest("hex");
          generateQrCodeNest(
              hash,
              d.apiKey,
              "Rejolut@1008",
              timestamp,
              "document"
          )
              .then((data) => {
                  this.listenForSocket();
                  this.setState({
                      showQr: true,
                      qrCodeData: data,
                      apiKey: this.state.apikey,
                      qrcode: false,
                  });
                  console.log("test");
              })
              .catch((err) => {
                  alert(err);
              });
      })
      .catch((err) => {
          alert(err);
      });
};




qrcode = () => {
    let { qrCodeData } = this.state;
    return (
        <div className="qr-container">
            <img src={qrCodeData} alt="new" />
        </div>
    );
};

redirectToHome() {
    window.location.href = "/";
}
redirectToGenerateCred() {
    window.location.href = "/generateCred";
}

redirectToVerifyCreditnals() {
    window.location.href = "/verifyCredentials";
}

//VERIFY CREDENTIALS

verifyQrCodeData = () => {
    // verifier_generateqr("shareCredentials")
    // .then((data) => {
    // // console.log("Error found")
    // this.verifier_listenForSocket();
    // this.setState({
    // showQr: true,
    // qrCodeData: data,
    // apiKey: this.state.apikey,
    // qrcode: false,
    // });
    // })
    // .catch((err) => {
    // alert(err.message);
    // });
    registerVendor()
        .then((d) => {
            const timestamp = Date.now();
            const hash = crypto
                .createHmac("sha256", d.secretKey)
                .update(JSON.stringify(d.apiKey + "Rejolut@1008" + timestamp))
                .digest("hex");
            generateQrCodeNest(
                hash,
                d.apiKey,
                "Rejolut@1008",
                timestamp,
                "shareCredentials",""
                
            )
                .then((data) => {
                    this.verifier_listenForSocket();
                    this.setState({
                        showQr: true,
                        qrCodeData: data,
                        apiKey: this.state.apikey,
                        qrcode: false,
                    });
                })
                .catch((err) => {
                    alert(err);
                    
                });
        })

        .catch((err) => {
            alert(err);
        });
};

verifier_listenForSocket = () => {
    verifier_listenForServiceProviderResponse((err, res) => {
        this.setState({
            qrcode: false,
            response: false,
            showSpinner: true,
            showText: false,
            
        });
    });

verifier_listenForUserData(async (err, res) => {
        let bytes = res.userDetails || res.newreq;
        console.log(bytes);
        if (bytes.permission === "denied" || bytes === "denied") {
            this.userDenied();
        } else {
            Swal.fire({
              html: `<table id="table" border=2>
              <thead>
      <tr>
          <th>Key</th>
          <th>Value</th>
      </tr>
  </thead>
              
              <tbody >

                  <tr>
                  <td>User Email</td>
                  <td>${bytes.userEmail}</td>    
                  </tr>

                  <tr>
                  <td>User Name</td>
                  <td>${bytes.userName}</td>    
                  </tr>

                  <tr>
                  <td>Mobile Number</td>
                  <td>${bytes.userMobileNo}</td>    
                  </tr>

                  <td>EarthID</td>
                  <td>${bytes.earthId}</td>    
                  </tr>

                  <tr>
                  <td>Permission</td>
                  <td>${bytes.permission}</td>    
                  </tr>
                  
                  <tr>
                  <td>Pressed</td>
                  <td>${bytes.pressed}</td>    
                  </tr>

                  <tr >
                  <td>KYC Token </td>
                  <td id= "kyc">${bytes.kycToken}</td>    
                  </tr>


                  
      </tbody>
      </table>`,
                // title: 'Are you sure?',
                text: bytes.credential,
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Verify",
                
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        position: "center",
                        //icon: 'success',
                        title: "Verifying....",
                        showConfirmButton: false,
                    });
                    verifyCreditnals(
                        bytes.credential,
                        bytes.publicKey,
                        bytes.signature,
                        bytes.verifyParams
                    ).then((data) => {
                      if (data.success === true) {
                          //interchanged verfication msg 
                          Swal.fire("Verification Failed");                        
                        } else {
                          Swal.fire("Verification Successful");
                        }
                    });
                }
            });
            this.setState({
                showQr: false,
                showSpinner: false,
            });
            if (bytes != null) {
                this.setState({
                    response: true,
                    showSpinner: false,
                    loggedIn: true,
                    isVerified: true,
                    showModal: false,
                    btnText: "LogOut",
                });
                setTimeout(() => {
                    document.getElementById("exampleModalCred").click();
                    let popMessage;
                    if (showNotification === true) {
                        if (bytes === "credentialsgenerated") {
                            popMessage = "Credentials Generated";
                        } else {
                            popMessage = `Credentials Received Successfully`;
                        }
                        showNotification = false;
                        store.addNotification({
                            title: "Success!",
                            message: popMessage,
                            type: "success",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 3000,
                                onScreen: true,
                            },
                        });
                        if (bytes == "login") {
                            this.updateNotificationState();
                        } else if (bytes === "verifylogin") {
                            this.verifyNotificationState();
                        }
                    }
                }, 0);
            } else {
                this.setState({
                    response: false,
                    showModal: false,
                });
                setTimeout(() => {
                    document.getElementById("exampleModalCred").click();
                    if (showNotification === true) {
                        showNotification = false;
                        store.addNotification({
                            title: "Error!",
                            message: `User Didn't Authorize!`,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 3000,
                                onScreen: true,
                            },
                        });
                    }
                }, 0);
            }
        }
    });
};




  render() {
    let showButton = this.state.showSpinner ? (
      <div className="spinner-div">
        <img className="spinner" src={require("./spinner.gif")} />
      </div>
    ) : null;
    return (
      <div className="App">

<nav className="navbar navbar-expand-sm navbar-light">
          <div
            className=" collapse navbar-collapse pull-right "
            id="navbarTogglerDemo01"
          >
            {/* <div className="clickable" onClick={() => window.open("https://www.myearth.id/")}> 
              <img src={logo} alt="logo" className="logo"  />{" "}
            </div> */}
            <div className="clickable" onClick={() => this.redirectToHome()}>
              <img src={require("./EarthID Logo - Primary - BLACK.png")} alt="logo" className="logo" width="160" height="50" />{" "}
              {/* <span className="logo-text">&nbsp;Issuer</span> */}
            </div>
            <ul className=" navbar-nav mr-auto mt-2 mt-lg-0" style={{marginLeft:"1.5rem", textTransform:"uppercase"
            , color:"White"}}>
                    <li class="nav-item">
                        <a class="nav-link active" aria-current="page" href="#">Home</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#">About</a>
                    </li>
            </ul>

            {localStorage.user_name ? (
              <div>
                <label className="welcomeLabel">{`Welcome, ${localStorage.getItem(
                  "user_name"
                )}`}</label>
              </div>
            ) : null}

<div className="profileAccess">

                

{window.location.pathname == "/" ? (
                <div>
                  <button
                    type="button"
                    className="nav-buttons"
                    data-toggle="modal"
                    data-target="#exampleModalLogin"
                    onClick={this.state.btnText=="LogOut"?this.redirectToHome:this.generateQrCodeData}
                    style={{ marginRight: "2rem", marginLeft: "2rem" }}
                  >
                    {this.state.btnText}
                  </button>
                  </div>
              ) : window.location.pathname == "/generateCred" ? (
                <div>
                  
                  <div className="dropdown">
      <button
        className="btn btn-secondary dropdown-toggle"
        type="button"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        Request Credentials
      </button>
      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <a
          className="dropdown-item"
          data-toggle="modal"
          data-target="#exampleModalCred"
          onClick={this.generateCredQrCodeData}
          style={{ marginRight: "2rem", marginLeft: "2rem" }}
        >
          Employment Badge
        </a>
        <a
          className="dropdown-item"
          data-toggle="modal"
          data-target="#exampleModalCred"
          onClick={this.generateCredQrCodeData}
          style={{ marginRight: "2rem", marginLeft: "2rem" }}
        >
          Course Certificate
        </a>
        <a
          className="dropdown-item"
          data-toggle="modal"
          data-target="#exampleModalCred"
          onClick={this.generateCredQrCodeData}
          style={{ marginRight: "2rem", marginLeft: "2rem" }}
        >
          Project Certificate
        </a>
      </div>
    </div>
                </div>
              ):(
                <div>Not found</div>
              )}
</div>
          </div>
        </nav>

        {/* <header> */}
        {/* <a href="https://www.myearth.id/" target="blank" className="logo__link">
            <img className="logo" src={logo} alt="brand_logo" />
        </a>

        <nav className="nav__links">
            <ul>
                <li><a href="">Services</a></li>
                <li><a href="">Business</a></li>
                <li><a href="">Corporates & Institutions</a></li>
                <li><a href="">Banking with us</a></li>

            </ul>
        </nav> */}

        {/* <div className="nav__buttons">
            <button data-modal-target="#modal">Request Credentials</button>
    <div className="modal" id="modal">
        
        <div className="modal-header">
            <div className="title">Waiting for user authorization</div>
        </div>

        <div className="modal-body">
            <img src="https://api.qrserver.com/v1/create-qr-code/?data={&quot;apikey&quot;:&quot;UKURGTREFATPSYJ&quot;,&quot;reqNo&quot;:&quot;BAZQUVRDTLF&quot;,&quot;sessionKey&quot;:&quot;BAZQUVRDTLF&quot;,&quot;requestType&quot;:&quot;login&quot;,&quot;encryptionkey&quot;:&quot;loginDetailsEncryption&quot; }" alt="QR-Code" />
            <div className="close-button-div">
                <button data-close-button className="close-button">Close</button>
            </div>
        </div>
    </div>

    <div id="overlay"></div>
            <a href="#" className="cta-1 cta-2"><button className="cta__buttons">Login with EarthiD</button></a>
        </div> */}
    {/* </header> */}
        {/* <header> */}
        <section class="home1">
            <div class="container">
              <div class="row">
<div class="col-md-6"></div>
                <div class="col-md-6">
                  <h3>LEARN - EXPLORE - GROW</h3>
                  <hr />
                  <p>Discover new possibilities and opportunities with us.</p>
                  <a class="left1" href="https://www.myearth.id/" target="_blank">Join Now!</a>
                </div>
              </div>
            </div>
          </section>
          <section class="home2">
            <div class="container">
              <h2>We're not just an online course provider, we're a community of learners and experts committed to your growth and success. </h2>
              <div class="row">
                <div class="extra-div col-md-4">
                  <h2>01</h2>
                  <h2>Convenience</h2>
                  <hr/>
                  <h3>Flexible: Learn on your own schedule and at your own pace with our self-paced courses.</h3>
                </div>
                <div class="extra-div col-md-4">
                  <h2>02</h2>
                  <h2>Affordability</h2>
                  <hr/>
                  <h3>Accessible: Quality education at a fraction of the cost.</h3>
                </div>
                <div class="extra-div col-md-4">
                  <h2>03</h2>
                  <h2>Expertise</h2>
                  <hr/>
                  <h3>Excellence: Learn from industry leaders and subject matter experts.</h3>
                </div>
              </div>
            </div>
          </section>

          <section class="home3">
            <div class="container">
              <div class="col-md-7">
                <h2>Experience Personalized Learning with Our Online Platform</h2>
                <h4>We offer a holistic approach to education that empowers learners to reach their full potential. Our engaging courses, expert instructors, and interactive learning tools provide a dynamic and personalized experience that's tailored to your needs. Say goodbye to traditional classroom constraints and hello to a world of limitless possibilities.</h4>

              </div>
            </div>
            <div class="row">
              <div class="col-md-5">
              </div>
              <div class="types col-md-4">
                <h2>01</h2>
                <h3>Personalization</h3>
                <p>We provide personalized learning experiences, with tailored course recommendations, progress tracking, and feedback to help learners achieve their goals.</p>
              </div>

            </div>
            <div class="row">
              <div class="col-md-2"></div>
              <div class="types1 col-md-4">
                <h2>02</h2>
                <h3>Innovation</h3>
                <p>We leverage cutting-edge technology and pedagogy to create engaging and interactive courses that keep learners motivated and help them retain knowledge.</p>
              </div>
              <div class="col-md-5">
              </div>
            </div>
            <div class="row">
              <div class="col-md-5">
              </div>
              <div class="types2 col-md-4">
                <h2>03</h2>
                <h3>Community</h3>
                <p>We foster a vibrant learning community, with opportunities for collaboration, networking, and support from peers, mentors, and instructors.</p>
              </div>

            </div>

          </section>

          <footer class="footerr">
            <div class="container">

              <div class="row">
                <div class="col-md-4">
                  <h2>EarthID
                  <br></br>Start your journey to success today</h2>

                </div>
                <div class="social col-md-8">
                  <h2>Follow Us:</h2>

                  <a href="https://twitter.com/earthid_ssi" target="blank"><FontAwesomeIcon icon={faTwitter} /></a>
                  <a href="https://www.facebook.com/myearth.id/" target="blank"><FontAwesomeIcon icon={faFacebook} /></a>
                  <a href="https://www.linkedin.com/company/myearthid/" target="blank"><FontAwesomeIcon icon={faLinkedinIn} /></a>
                  <a href="https://www.youtube.com/channel/UCaRjLyN4I_0UrK3NL4zU1Vg/featured" target="blank"><FontAwesomeIcon icon={faYoutube} /></a>
                  <a href="https://www.instagram.com/myearthid/" target="blank"><FontAwesomeIcon icon={faInstagram} /></a>
                  <a href="#"><FontAwesomeIcon icon={faEnvelope} /></a>

                </div>
              </div>
            </div>
          </footer>

          <div className="modal_container" id="modal-container">
            <div className="modal">
              <div className="qr-code">
                <img
                  src='https://api.qrserver.com/v1/create-qr-code/?data={"apikey":"UKURGTREFATPSYJ","reqNo":"BAZQUVRDTLF","sessionKey":"BAZQUVRDTLF","requestType":"login","encryptionkey":"loginDetailsEncryption" }'
                  alt="QR-Code"
                />
              </div>

              <button id="btn-close">Close me</button>
            </div>
          </div>
          {/* </header> */}

         
          <div className="hero-section">
          <div className="shoppingCart"></div>
          <div
            className="modal fade"
            id="exampleModalLogin"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              {
                <div>
                  {this.state.showModal ? (
                    <div className="modal-content">
                      <div className="modal-header">
                        <h4 style={{ color: "black" }}>
                          {this.state.modalHeading && !this.state.showSpinner
                            ? "Use Earth ID app to scan the QR code"
                            : "Waiting for user authorization"}
                        </h4>
                      </div>
                      <div className="modal-body">
                        <div className="pop-upbox">
                          {showButton}
                          {this.state.response
                            ? null
                            : this.state.qrcode
                              ? this.generateQrCodeData()
                              : null}
                          {this.state.showQr ? (
                            this.qrcode()
                          ) : this.state.showSpinner ? (
                            <img
                              className="spinner"
                              src={require("./spinner.gif")}
                            />
                          ) : null}
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-secondary p-btn"
                          data-dismiss="modal"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  ) : null}
                </div>
              }
            </div>
          </div>
        </div>
     
         <div className="hero-section">
          <div className="shoppingCart"></div>
          <div
            className="modal fade"
            id="exampleModalCred"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              {
                <div>
                  {this.state.showModal ? (
                    <div className="modal-content">
                      <div className="modal-header">
                        <h4>
                          {this.state.modalHeading && !this.state.showSpinner
                            ? "Use Earth ID app to scan the QR code"
                            : "Waiting for user authorization"}
                        </h4>
                      </div>
                      <div className="modal-body">
                        <div className="pop-upbox">
                          {showButton}
                          {this.state.response
                            ? null
                            : this.state.qrcode
                              ? this.generateCredQrCodeData()
                              : null}
                          {this.state.showQr ? (
                            this.qrcode()
                          ) : this.state.showSpinner ? (
                            <img
                              className="spinner"
                              src={require("./spinner.gif")}
                            />
                          ) : null}
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-secondary p-btn"
                          data-dismiss="modal"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  ) : null}
                </div>
              }
            </div>
          </div>
        </div>
      
      
      </div>
    );
  }
}

export default Home;
