import React, { Component } from "react";
import CryptoJS from "react-native-crypto-js";
import "./generateCred.scss";
import { QRCode } from "react-qr-svg";
import data from '../../api';
import { store } from 'react-notifications-component';
const panCard = require('../../images/panCard.jpg')
const drivingLicence = require('../../images/driving-license.jpeg')
const voterId = require('../../images/voterid.jpeg')
const { listenForUserData, listenForServiceProviderResponse, generateqr, issuerDidgenerateqr } = data

let showNotification = true;

class GenerateCredentials extends Component {
  state = {
    profileDrop: false,
    requestNo: '',
    openModal: true,
    qrcode: false,
    response: false,
    showSpinner: false,
    modalHeading: false,
    selectedList: 'List of Documents',
    documents: [],
    decodedDocbase64: ''
  }

  redirectToHome() {
    window.location.href = '/';
  }

  componentWillMount() {
    // if (!localStorage.email) {
    //   this.redirectToHome()
    // }
  }

  updateNotificationState = () => {
    setTimeout(() => {
      showNotification = true;
    }, 100);
  }

   listenForSocket = async () => {
    console.log('gen')
    listenForServiceProviderResponse((err, res) => {
      this.setState({
        qrcode: false,
        response: false,
        showSpinner: true,
        showText: false
      })
    })
    
    listenForUserData(async(err, res) =>  {
      console.log('data::::::::ddddd11111::::::',res)
      let bytes = []
      
      this.setState({
        showQr: false,
        showSpinner: true,
      })
      if (false === false) {
        
        setTimeout(async() => {
          this.setState({
            response: true,
            showModal: true,
            showSpinner: false,
            btnText: 'LogOut',
            DeniedHeading: false,
          })
          console.log('ggfgfgfgfg::::::', this.state.documents, this.state.showModal, this.state.decodedDocbase64)
          document.getElementById('exampleModal').click();
        }, 0);
      } else {
        this.setState({
          profileDrop: false,
          requestNo: '',
          openModal: false,
          qrcode: false,
          response: false,
          showSpinner: false,
          modalHeading: false,
          selectedList: 'List of Documents',
        })
        setTimeout(() => {
          document.getElementById('exampleModal').click();
          if (showNotification === true) {
            showNotification = false;
            store.addNotification({
              title: "Error!",
              message: `User Didn't Authorize!`,
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: {
                duration: 3000,
                onScreen: true
              }
            });
            //this.updateNotificationState();
          }
        }, 0);
      }
    })
  }

 
  generateQrCodeData = () => {
    issuerDidgenerateqr().then(data => {
      this.listenForSocket();
      data = JSON.parse(data)
      data.encryptionkey = "documentDetailsEncryption";
      data.requestType = "Document";
      this.setState({
        showQr: true,
        qrCodeData: JSON.stringify(data),
        apiKey: this.state.apikey,
        qrcode: false
      })
    }).catch((err) => {
      alert(err)
    })
  }

  qrcode = () => {
    let { qrCodeData } = this.state
    return (
      <div className="qr-container">
        <QRCode
          value={`${qrCodeData}`}
          bgColor="#FFFFFF"
          fgColor="#000000"
          level="Q"
          style={{ width: 256 }}
        />
      </div>
    );
  };

  render() {
    document.data = ''
    if (!localStorage.email) {
      return null;
    }
    let showButton = this.state.showSpinner ? (
      <div className="spinner-div"><img className="spinner" src={require('../../../src/spinner.gif')} /></div>
    ) : null
    return (
      <div className='wrapperDocuments'>
        <div className="modalFade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            {this.state.response ? '' :
              <div>
                {this.state.openModal ?
                  <div className="modal-content" style={{ width: '500px' }}>
                    <div className="modal-header">
                      <h4>
                        {this.state.modalHeading && !this.state.showSpinner ? 'Use Earth ID app to scan the QR code' : 'Waiting for user authorization'}
                      </h4>
                    </div>
                    <div className="modal-body">
                      <div className="pop-upbox">
                        {showButton}
                        {this.state.response ? null : this.state.qrcode ? this.generateQrCodeData() : null}
                        {this.state.showQr ? this.qrcode() : this.state.showSpinner ? <img className="spinner" src={require('../../../src/spinner.gif')} alt='spin' /> : null}
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary p-btn" data-dismiss="modal" onClick={() => { this.setState({ openModal: false }) }} >Close</button>
                    </div>
                  </div>
                  : null}
              </div>}
          </div>
        </div>
        {this.state.openModal ? '' :
          <div className='docDopdown'>
            {/* <div className='documentList'>{this.state.selectedList}
              <i class='fas fa-angle-down downArrow' onClick={() => { this.setState({ profileDrop: !this.state.profileDrop }) }}></i>
            </div>
            {this.state.profileDrop ? <div>
              <ul className="document-list">
                <li className="document" onClick={() => { this.setState({ selectedList: 'PAN CARD', profileDrop: false, qrcode: true, modalHeading: true }) }}>PAN CARD</li>
                <li className="document" onClick={() => { this.setState({ selectedList: 'DRIVING LICENCE', profileDrop: false, qrcode: true, modalHeading: true }) }}>DRIVING LICENCE</li>
                <li className="document" onClick={() => { this.setState({ selectedList: 'VOTER ID', profileDrop: false, qrcode: true, modalHeading: true }) }}>VOTER ID</li>
              </ul>
            </div> : ""} */}
            <button className='register' onClick={() => { this.setState({ openModal: true, profileDrop: false, qrcode: true, modalHeading: true }) }} >Submit Details to Generate credentials with Earth Id</button>
          </div>}
          {console.log(this.state)}
        {this.state.showModal ?
        (this.state.documents.map((document) => {
          console.log('fffff::::',document)
          return (<div className='doc-list'>
            <div className='modalMain'>
            <div className='modalMainSub'>
              <h2>{document.name}</h2>
              {/* <img className='aadharIcon' src={document == 'PAN CARD' ? panCard : document == 'DRIVING LICENSE' ? drivingLicence : voterId} /> */}
              {document.contentType == 'pdf' ? <embed width="100%" height="100%" name="plugin" src={`data:application/pdf;base64,${this.state.decodedDocbase64}`}></embed> : <img className='aadharIcon' src={`data:image/jpeg;base64,${this.state.decodedDocbase64}`} style={{width: '300px'}} />}
            </div>
          </div>
          </div>)
  })) : console.log('hi hi hi:::::')}
      </div>
    );
  }
}

// 0: "PAN CARD"
// 1: "DRIVING LICENSE"
// 2: "VOTER ID"

export default GenerateCredentials;