import React, { Component } from "react";
import openSocket from "socket.io-client";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import "./App.css";
import Home from "./home";
import Certificate from "./components/ProfCred/certificate";
import CreateCred from "./generateCred";
import EmpCertificate from "./components/EmpCred/certificate";

export const isTestnet = true;
const socketURL =
  isTestnet === true
    ? "https://socket-stagev2.myearth.id"
    : "https://socketv2.myearth.id";
const socket = openSocket(socketURL, {
  transports: ["websocket", "polling", "flashsocket"],
});
let socketId;
socket.on("connect", () => {
  socketId = socket.id;
  console.log(`I'm connected with the back-end`);
});



class App extends Component {


  render() {
    return (
      <div className="App">


          <div className="content">
            <Router>
              <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/generateCred" component={CreateCred} />
              <Route path="/certificate/" component={Certificate} />
              <Route path="/employment-certificate/" component={EmpCertificate} />
              </Switch>
            </Router>
          </div>
      </div>
    );
  }
}

export default App;
